import React from 'react';
// import LogoImg from '../../../../public/assets/images/svg/logo.svg';
import S3StaticImage from '../CustomImage/S3StaticImage';
import Layout from '../Layout/Layout';
import MyLink from '../Link/MyLink';

interface Props {
    width: string;
    height?: string;
}
const Logo = ({ width, height }: Props) => {
    return (
        <MyLink pageName="mainPage">
            <Layout style={{ cursor: 'pointer', width, height }}>
                <S3StaticImage
                    path={'/svg/logo.svg'}
                    alt={'logo'}
                    containerStyle={{ width, height }}
                />

                {/* <LogoImg width={width} height={height} /> */}
            </Layout>
        </MyLink>
    );
};

export default Logo;
