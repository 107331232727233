import styles from './header.module.scss';
import MyLink from '../../../../../shared/components/Link/MyLink';
import Logo from '../../../../../shared/components/Logo/Logo';

const HeaderLogo = () => (
    <div className={styles.logo}>
        <MyLink pageName={'mainPage'}>
            <Logo width={'100%'} />
        </MyLink>
    </div>
);

export default HeaderLogo;
