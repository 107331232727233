import React from 'react';
import Footer from '.';
import styles from './footerWrapper.module.scss';

interface Props {
    children: React.ReactNode;
}
const FooterWrapper = ({ children }: Props) => {
    return (
        <div className={styles.container}>
            {children}
            <Footer />
        </div>
    );
};

export default FooterWrapper;
