import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
    level:
        | 'caption1Regular'
        | 'caption1Medium'
        | 'caption1Strong'
        | 'caption2Regular'
        | 'caption2Medium'
        | 'caption2Strong';
    className?: string;
    isEllipsis?: boolean;
}
const Caption = ({
    children,
    level,
    className,
    isEllipsis,
    style,
    ...props
}: Props) => {
    return (
        <div
            className={classNames(className, {
                [styles.caption1Regular]: level === 'caption1Regular',
                [styles.caption1Medium]: level === 'caption1Medium',
                [styles.caption1Strong]: level === 'caption1Strong',
                [styles.caption2Regular]: level === 'caption2Regular',
                [styles.caption2Medium]: level === 'caption2Medium',
                [styles.caption2Strong]: level === 'caption2Strong',
            })}
            style={
                isEllipsis
                    ? {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          ...style,
                      }
                    : style
            }
            {...props}
        >
            {children}
        </div>
    );
};

export default React.memo(Caption);
