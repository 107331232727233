import React from 'react';
import { useRecoilValue } from 'recoil';
import styles from './header.module.scss';
import { isSignedInState } from '../../../../auth/states/isSignedInState';
import MyButton from '../../../../../shared/components/Button/MyButton';
import { memberInfoState } from '../../../../auth/states/memberInfoState';
import {
    isInvesteeUser,
    isMentorUser,
} from '../../../../../shared/utils/memberUtil';
import FlexLayout from '../../../../../shared/components/Layout/FlexLayout';
// import ProfileIcon from '../../../../../../public/assets/images/png/header_profile_icon.png';
import MyLink from '../../../../../shared/components/Link/MyLink';
import S3StaticImage from '../../../../../shared/components/CustomImage/S3StaticImage';

const HeaderRight = () => {
    const isSignedIn = useRecoilValue(isSignedInState);

    const { userType } = useRecoilValue(memberInfoState);

    return (
        <FlexLayout className={styles.right} gap={'12px'} align="center">
            {!isMentorUser(userType) && (
                <MyLink pageName="becomeAMentorPage">
                    <MyButton className={styles.become} variant="ghost">
                        Become a mentor
                    </MyButton>
                </MyLink>
            )}

            {isSignedIn === 'SIGNED_IN' ? (
                <>
                    <MyLink
                        pageName={
                            isInvesteeUser(userType)
                                ? 'startUpProfilePage'
                                : 'investorProfilePage'
                        }
                    >
                        <button
                            style={{
                                border: 'none',
                                background: 'transparent',
                            }}
                            className={styles.profile}
                        >
                            <S3StaticImage
                                path={'/png/header_profile_icon.png'}
                                alt={'ProfileIcon'}
                                layout="intrinsic"
                            />
                            {/* <CustomImage
                                layout="intrinsic"
                                src={ProfileIcon}
                                alt=""
                            /> */}
                        </button>
                    </MyLink>
                </>
            ) : (
                <MyLink pageName="signInPage">
                    <MyButton>Get started</MyButton>
                </MyLink>
            )}
        </FlexLayout>
    );
};

export default HeaderRight;
