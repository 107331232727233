import React from 'react';
import styles from './header.module.scss';
import HeaderLogo from './HeaderLogo';
import HeaderRight from './HeaderRight';
import FlexLayout from '../../../../../shared/components/Layout/FlexLayout';

function Header() {
    return (
        <FlexLayout className={styles.layout} align={'center'}>
            <FlexLayout
                className={styles.container}
                align={'center'}
                justify="space-between"
            >
                <HeaderLogo />
                <HeaderRight />
            </FlexLayout>
        </FlexLayout>
    );
}

export default Header;
