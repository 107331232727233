import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
    level:
        | 'display'
        | 'title0'
        | 'title1'
        | 'title2'
        | 'title3'
        | 'title4'
        | 'title5';
    className?: string;
    isEllipsis?: boolean;
}
const Title = ({
    children,
    level,
    className,
    isEllipsis,
    style,
    ...props
}: Props) => {
    return (
        <div
            className={classNames(className, {
                [styles.display]: level === 'display',
                [styles.title0]: level === 'title0',
                [styles.title1]: level === 'title1',
                [styles.title2]: level === 'title2',
                [styles.title3]: level === 'title3',
                [styles.title4]: level === 'title4',
                [styles.title5]: level === 'title5',
            })}
            style={
                isEllipsis
                    ? {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          ...style,
                      }
                    : style
            }
            {...props}
        >
            {children}
        </div>
    );
};

export default React.memo(Title);
