import React from 'react';
import { Flex, FlexProps } from 'antd';

interface Props extends FlexProps {
    children: React.ReactNode;
}
const FlexLayout = ({ children, ...props }: Props) => {
    return <Flex {...props}>{children}</Flex>;
};

export default FlexLayout;
