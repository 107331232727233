import React, { useEffect, useState } from 'react';
import styles from './footer.module.scss';
// import FooterLogo from '../../../../public/assets/images/svg/logo_footer.svg';
import MyLink from '../../../shared/components/Link/MyLink';
import { useRouter } from 'next/router';
import Body from '../../../shared/components/Typography/Body';
import Caption from '../../../shared/components/Typography/Caption';
import FlexLayout from '../../../shared/components/Layout/FlexLayout';
import Layout from '../../../shared/components/Layout/Layout';
import DeferredComponent from '../../../shared/components/DeferredComponent';
import S3StaticImage from '../../../shared/components/CustomImage/S3StaticImage';
const Footer = () => {
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleStart = (url) =>
            url !== router.pathname && setLoading(true);
        const handleComplete = (url) =>
            url === router.pathname && setLoading(false);

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, [router]);

    return (
        <DeferredComponent delayMs={10}>
            <Layout
                id={styles.footer}
                style={{ display: loading ? 'none' : 'block' }}
            >
                <FlexLayout className={styles.inner}>
                    <FlexLayout vertical gap={'8px'} className={styles['logo']}>
                        {/* <FooterLogo /> */}
                        <S3StaticImage
                            path={'/svg/logo_footer.svg'}
                            alt={'logo'}
                        />
                        <Body level="body2Regular">
                            엔젤라운지 주식회사 All rights reserved
                        </Body>
                    </FlexLayout>
                    <FlexLayout
                        vertical
                        gap={'8px'}
                        className={styles['terms']}
                    >
                        <MyLink pageName="termsOfServicePage">
                            <Caption level="caption1Strong">
                                Terms of Use
                            </Caption>
                        </MyLink>
                        <MyLink pageName="privacyPolicyPage">
                            <Caption level="caption1Strong">
                                Privacy Policy
                            </Caption>
                        </MyLink>
                        <MyLink pageName="googleAPIDisclosurePage">
                            <Caption level="caption1Strong">
                                Google API Disclosure
                            </Caption>
                        </MyLink>
                        <MyLink pageName="feedbackServicePoliciesPage">
                            <Caption level="caption1Strong">
                                Feedback service policies and security duties
                            </Caption>
                        </MyLink>
                    </FlexLayout>
                    <FlexLayout vertical gap={'8px'} className={styles['info']}>
                        <Caption level="caption1Strong">Information</Caption>
                        <FlexLayout vertical gap={'4px'}>
                            <FlexLayout gap={'4px'} align="center">
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    엔젤라운지 주식회사
                                </Caption>
                            </FlexLayout>
                            <FlexLayout gap={'4px'} align="center">
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    대표
                                </Caption>
                                <div className={styles['divider']} />
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    최은성
                                </Caption>
                            </FlexLayout>
                            <FlexLayout gap={'4px'} align="center">
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    개인정보관리책임자
                                </Caption>
                                <div className={styles['divider']} />
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    김현구
                                </Caption>
                            </FlexLayout>
                            <FlexLayout gap={'4px'}>
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    서울시 서초구 강남대로 53길 8, 8층 8-5
                                </Caption>
                            </FlexLayout>
                            <FlexLayout gap={'4px'} align="center">
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    사업자등록번호
                                </Caption>
                                <div className={styles['divider']} />
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    379-87-02448
                                </Caption>
                            </FlexLayout>
                            <FlexLayout gap={'4px'} align="center">
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    통신판매업
                                </Caption>
                                <div className={styles['divider']} />
                                <Caption
                                    className={styles.caption}
                                    level="caption1Regular"
                                >
                                    2022-서울서초-2306
                                </Caption>
                            </FlexLayout>
                        </FlexLayout>
                    </FlexLayout>
                    <FlexLayout
                        vertical
                        gap={'8px'}
                        className={styles['contact']}
                    >
                        <Caption level="caption1Strong">Contact</Caption>
                        <FlexLayout vertical gap={'4px'}>
                            <Caption level="caption1Regular">
                                070-8672-3160
                            </Caption>
                            <Caption level="caption1Regular">
                                biz@angelounge.com
                            </Caption>
                        </FlexLayout>
                    </FlexLayout>
                </FlexLayout>
            </Layout>
        </DeferredComponent>
    );
};

export default Footer;
