import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
    level:
        | 'body1Regular'
        | 'body1Medium'
        | 'body1Strong'
        | 'body2Regular'
        | 'body2Medium'
        | 'body2Strong';
    className?: string;
    isEllipsis?: boolean;
}
const Body = ({
    children,
    level,
    className,
    isEllipsis,
    style,
    ...props
}: Props) => {
    return (
        <div
            className={classNames(className, {
                [styles.body1Regular]: level === 'body1Regular',
                [styles.body1Medium]: level === 'body1Medium',
                [styles.body1Strong]: level === 'body1Strong',
                [styles.body2Regular]: level === 'body2Regular',
                [styles.body2Medium]: level === 'body2Medium',
                [styles.body2Strong]: level === 'body2Strong',
            })}
            style={
                isEllipsis
                    ? {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          ...style,
                      }
                    : style
            }
            {...props}
        >
            {children}
        </div>
    );
};

export default React.memo(Body);
